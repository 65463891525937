import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createBlock(_component_el_image, {
    style: _normalizeStyle({
      height: _ctx.height,
      maxHeight: _ctx.height,
      padding: _ctx.padding,
      width: _ctx.width,
      maxWidth: _ctx.maxWidth,
    }),
    src: _ctx.imageSrc,
    fit: `scale-down`
  }, {
    error: _withCtx(() => [
      (_ctx.showError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.errorDefault === 'icon')
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "fa fa-image text-light",
                  style: _normalizeStyle({
            fontSize: _ctx.fontSize,
          })
                }, null, 4))
              : _createCommentVNode("", true),
            (_ctx.errorDefault === 'text')
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  style: _normalizeStyle({
            fontSize: _ctx.fontSize,
            color: _ctx.textColor,
            backgrounColor: _ctx.backgrounColor,
          })
                }, _toDisplayString(_ctx.errorText), 5))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["style", "src"]))
}