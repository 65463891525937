import { withKeys as _withKeys, withModifiers as _withModifiers, vModelText as _vModelText, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a47964a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-tag d-flex" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  class: "tags",
  ref: "tagsUl"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      onKeydown: [
        _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.addTag(_ctx.tag)), ["enter"])),
        _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.addTag(_ctx.tag)), ["prevent"]), ["tab"])),
        _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.tag.length || _ctx.removeTag(_ctx.tags.length - 1)), ["delete"])),
        _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleSeparator && _ctx.handleSeparator(...args)))
      ],
      onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addTag(_ctx.tag))),
      style: _normalizeStyle(`padding-left: ${_ctx.paddingLeft}px`),
      type: "text",
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.tag) = $event)),
      class: "form-control",
      placeholder: _ctx.newPlaceholder,
      ref: "inputTag"
    }, null, 44, _hoisted_2), [
      [_vModelText, _ctx.tag]
    ]),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "tag"
        }, [
          _createTextVNode(_toDisplayString(tag) + " ", 1),
          _createElementVNode("button", {
            class: "delete btn-close",
            type: "button",
            "aria-label": "Close",
            onClick: ($event: any) => (_ctx.removeTag(index))
          }, " x ", 8, _hoisted_4)
        ]))
      }), 128))
    ], 512),
    _renderSlot(_ctx.$slots, "append", {}, undefined, true)
  ]))
}