
import { defineComponent } from 'vue';
import Header from '@/views/new-design/pages/ViewTenantReview/Report/Header.vue';
import Title from '@/views/new-design/pages/ViewTenantReview/Report/Title.vue';
import TenantHeader from '@/views/new-design/pages/ViewTenantReview/Report/TenantHeader.vue';
import ReportContent from '@/views/new-design/pages/ViewTenantReview/Report/ReportContent.vue';
import { TenantReview } from '@/models/TenantReviewModel';

export default defineComponent({
  props: {
    fileName: {
      type: String,
      default: 'Report',
    },
    orientation: {
      type: String,
      default: 'portrait',
    },
    info: {
      type: Object,
      default: () => {
        return {} as unknown as TenantReview;
      },
    },
    averageRating: {
      type: String,
      default: '0.00',
    },
    map: {
      type: String,
      default: '',
    },
    reviews: {
      type: String,
      default: '0',
    },
  },
  components: {
    Header,
    Title,
    TenantHeader,
    ReportContent,
  },
  data: () => ({
    tenantReview: 0,
    totalAverageRating: 0,
  }),
  watch: {
    info: {
      async handler(value) {
        this.tenantReview = await value;
      },
      deep: true,
      immediate: true,
    },
    averageRating: {
      async handler(value) {
        this.totalAverageRating = await value;
      },
      deep: true,
      immediate: true,
    },
  },
});
