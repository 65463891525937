
import { defineComponent } from 'vue';
import timeUtil from '@/utils/time';
import text from '@/utils/text';
import BusinessLogo from '@/components/logo/BusinessLogo.vue';
import Avatar from '@/components/avatars/Avatar.vue';
import Template from '@/views/pages/settings/templates/Template.vue';
import NoReviewSubmitted from '@/views/new-design/pages/ViewTenantReview/NoReviewSubmitted.vue';
import { money } from '@/utils/currency';
import { BadgeColors } from '@/models/ReviewAnswerModel';
import { TenantReview } from '@/models/TenantReviewModel';
import { Getters } from '@/store/enums/StoreEnums';
import { mapGetters } from 'vuex';

const badgeColor = BadgeColors;

const initValues = {
  rent_per_week: 0,
} as unknown as TenantReview;

export default defineComponent({
  props: {
    info: {
      type: Object,
      default: () => {
        return {} as unknown as TenantReview;
      },
    },
    map: {
      type: String,
      default: '',
    },
  },
  components: {
    BusinessLogo,
    Avatar,
    NoReviewSubmitted,
  },
  data: () => ({
    tenantReview: initValues,
    mapSrc: '',
  }),
  computed: {
    ...mapGetters({
      defaultTextColor: Getters.GET_DEFAULT_TEXT_COLOR,
    }),
    hasEmail() {
      return (this.info as any)?.user?.email;
    },
    hasMobileNumber() {
      return (this.info as any)?.user?.mobile_number;
    },
    address() {
      return (this.info as any)?.user?.roles[0].role == 'Admin'
        ? null
        : `${(this.info as any)?.user?.business_address_line}, ${
            (this.info as any)?.user?.business_city
          }, ${(this.info as any)?.user?.business_state}, ${
            (this.info as any)?.user?.business_post_code
          }`;
    },
    tenantAddress() {
      return `${(this.info as any)?.address_line}, ${
        (this.info as any)?.city
      }, ${(this.info as any)?.post_code}, ${(this.info as any)?.state}`;
    },
    businessName() {
      return (this.info as any)?.user?.business_name;
    },
    businessLogoSrc() {
      return (this.info as any)?.user?.business_logo_src;
    },
    businessBackgroundColor() {
      return (this.info as any)?.user?.business_color;
    },
    businessTextColor() {
      return (this.info as any)?.user?.business_text_color;
    },
    agentAvatarSrc() {
      return (this.info as any)?.user?.avatar_src;
    },
    agentAcronym() {
      const firstName = (this.info as any)?.user?.first_name;
      const lastName = (this.info as any)?.user?.last_name;

      if (firstName === '' && lastName === '')
        return text.acronym((this.info as any)?.user?.email);

      return text.acronym(`${firstName} ${lastName}`);
    },
    headerColor() {
      if (!(this.info as any)?.user?.business_color) {
        return '#4070ea';
      }

      return (this.info as any)?.user?.business_color ?? '#4070ea';
    },
    formattedAbn() {
      return this.tenantReview?.user?.abn
        ? text.formatABN(this.tenantReview?.user?.abn)
        : null;
    },
    formattedAcn() {
      return this.tenantReview?.user?.acn
        ? text.formatACN(this.tenantReview?.user?.acn)
        : null;
    },
    notReviewed() {
      return this.tenantReview?.is_not_reviewed === 'yes';
    },
    rentPerWeek() {
      return this.tenantReview?.rent_per_week !== 'undefined'
        ? money.format(parseFloat(this.tenantReview?.rent_per_week as string))
        : '0';
    },
  },
  methods: {
    getColor(answer = '' as string) {
      return typeof answer !== 'undefined' && answer !== null
        ? badgeColor[answer.toLocaleLowerCase()]
        : '';
    },
    dateFormat(date) {
      return timeUtil.dateFormat(date);
    },
    numberWithCommas(x: any) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    formatAverageRating(rating) {
      return text.numberFormat(rating);
    },
    renderPhoneNumber(phoneNo) {
      return typeof phoneNo !== 'undefined' && phoneNo ? `tel:${phoneNo.replace(/[^0-9]/g, '')}` : '';
    },
  },
  watch: {
    info: {
      async handler(value) {
        this.tenantReview = await value;
      },
      deep: true,
      immediate: true,
    },
    map: {
      async handler(value) {
        this.mapSrc = await value;
      },
      deep: true,
      immediate: true,
    },
  },
});
