import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f522425a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report-header d-flex align-items-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainLogo = _resolveComponent("MainLogo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MainLogo, { class: "header-logo" })
  ]))
}