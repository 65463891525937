
import { defineComponent, ref } from 'vue';

const __default__ = defineComponent({
  props: {
    modelValue: { type: Array, default: () => [] },
    placeholder: { default: '' },
    unique: { default: false },
    regex: { default: '' },
    separators: { type: Array, default: () => [] },
    isRequired: { type: Boolean, default: false },
    color: { type: String, default: '#409EFF' },
    limit: { type: Number, default: 0 },
    textColor: { type: String, default: '#ffffff' },
  },
  data() {
    return {
      tag: '',
      paddingLeft: 10,
    };
  },
  emits: ['update:modelValue'],
  computed: {
    tags: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    newPlaceholder() {
      return this.tags.length ? '' : this.placeholder;
    },
  },
  watch: {
    tags: {
      handler() {
        this.$nextTick(() => {
          this.setLeftPadding();
        });
      },
      deep: true,
    },
  },
  methods: {
    addTag(tag) {
      this.tag = this.tag.trim();
      tag = tag.trim();

      if (this.tag.trim() == '') {
        return;
      }

      if (this.unique && (this.tags as any).includes(tag)) {
        this.tag = '';
        return;
      }

      if (this.regex && tag.match(this.regex) == null) {
        this.tag = '';
        return;
      }

      if (this.limit > 0 && this.limit >= this.tags.length) {
        this.tag = '';
        return;
      }

      (this.tags as any).push(tag);
      this.tag = '';
    },
    removeTag(index) {
      var tags = [...this.tags];
      tags.splice(index, 1);

      this.tags = tags;
    },
    setLeftPadding() {
      const extraCushion = 15;
      const tagContainer = this.$refs.tagsUl as any;
      const inputTag = this.$refs.inputTag as any;

      this.paddingLeft = tagContainer.clientWidth + extraCushion;
      inputTag.focus();
    },
    handleSeparator(event: Event) {
      if (this.separators.length) {
        if (this.separators.includes((event as any).key)) {
          this.addTag((event as any).target.value);
          this.tag = '';
        }
      }
    },
  },
  mounted() {
    this.setLeftPadding();
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "78209565": (_ctx.color),
  "27e47990": (_ctx.textColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__