import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57326b2f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report-container" }
const _hoisted_2 = { class: "report-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Title = _resolveComponent("Title")!
  const _component_TenantHeader = _resolveComponent("TenantHeader")!
  const _component_ReportContent = _resolveComponent("ReportContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_Title, { title: "Tenant Reference Report" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TenantHeader, {
        reviews: _ctx.reviews,
        "average-rating": _ctx.totalAverageRating,
        info: _ctx.info
      }, null, 8, ["reviews", "average-rating", "info"]),
      (_ctx.map != '')
        ? (_openBlock(), _createBlock(_component_ReportContent, {
            key: 0,
            map: _ctx.map,
            info: _ctx.tenantReview
          }, null, 8, ["map", "info"]))
        : _createCommentVNode("", true)
    ])
  ]))
}