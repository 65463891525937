
import { defineComponent } from 'vue';
import maskUtil from '@/utils/mask';
import text from '@/utils/text';
import Avatar from '@/components/avatars/Avatar.vue';
import { User } from '@/models/UserModel';

export default defineComponent({
  props: {
    info: {
      default: () => {
        return {} as unknown as User;
      },
    },
    averageRating: {
      type: String,
      default: '0.00',
    },
    reviews: { default: 0 },
  },
  components: {
    Avatar,
  },
  computed: {
    rate() {
      return text.numberFormat(this.averageRating, 1);
    },
    mobileMasking() {
      return maskUtil.mobile.aus;
    },
    fullName() {
      const firstName = text.capitalize((this.info as any)?.first_name || '');
      const lastName = text.capitalize((this.info as any)?.last_name || '');

      if (firstName == '' && lastName == '') {
        return 'Not Specified';
      }

      return `${firstName} ${lastName}`;
    },
    tenantAcronym() {
      const firstName = this.info?.first_name;
      const lastName = this.info?.last_name;

      if (firstName == '' && lastName == '')
        return text.acronym(this.info?.email);

      return text.acronym(`${firstName} ${lastName}`);
    },
    tenantAvatarSrc() {
      return (this.info as any)?.avatar_src;
    },
    tenantRole() {
      return 'Tenant';
    },
    reviewsCount() {
      return `${this.reviews} reviews`;
    },
  },
});
